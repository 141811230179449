import { ApiResponse } from '@models';

export enum SyncStatus {
    STATUS_RETRIEVING = 'Retrieving Status',
    STATUS_PENDING = 'Pending',
    STATUS_SUCCESS = 'Succeeded',
    STATUS_FAILED = 'Failed',
    STATUS_UNKNOWN = 'Unknown',
    STATUS_CREATED = 'CREATED',
}

export type UseMainValidApiCalls =
    | 'sessions'
    | 'orders'
    | 'places'
    | 'transporterTypes';
export type UseMainApiResponse<R> = Pick<ApiResponse<R>, 'data'>;

export type AddTemplateHandlerResponse = {
    status: boolean | null;
    message?: string;
};

export type AddTemplateHandlerOption = { key: string; value: string | boolean };

export type AddTemplateHandler = <T>(
    description: string,
    body: T,
    type?: string,
    force?: boolean,
    options?: Array<AddTemplateHandlerOption>
) => Promise<AddTemplateHandlerResponse>;
