/* eslint-disable no-param-reassign */
import { initialWaypointValue } from '@components/SolverSessions/SessionCreator/SessionCreator.helper';
import { transformers } from '@flo-concepts/data-parser';
import { DateTime } from 'luxon';
import { v4 as uuidv4 } from 'uuid';
import _, { uniq } from 'lodash';
import { useApiContext } from '../apiclient';

export default ({ store }) => {
    const apiClient = useApiContext();

    const currentSession = {
        clear: async () => {
            store.currentSession.sessionID = null;
        },
    };

    const globalSessions = {
        clear: async () => {
            store.globalSessions.data = {
                systemConfiguration: {
                    dataFetcherConfiguration: {},
                    dqRulesConfiguration: {},
                    sessionPublisherConfiguration: {},
                    defaultValuesConfiguration: {},
                    userGroupConfiguration: {},
                    multiIterationSetup: {},
                    systemDefaults: {},
                },
                places: [],
                types: [],
                report: {},
                resources: [],
                rules: [],
                filteredRules: [],
                zones: [],
                orders: [],
                hydratedOrders: [],
                hydratedOrdersCount: 0,
                filteredOrders: [],
                filteredOrdersCount: undefined,
                liveTransporters: [],
            };
            store.globalSessions.activeFilters = [];
        },
        changeActiveFilters(value) {
            store.globalSessions.activeFilters = value;
        },
        clearResources() {
            store.globalSessions.data.resources = [];
        },
        addExcelResources(resources) {
            const extraFields = [
                'startLocationName',
                'startLocationCode',
                'endLocationName',
                'endLocationCode',
                'startTime',
                'endTime',
            ];

            store.globalSessions.clearResources();
            resources.forEach((resource) => {
                extraFields.forEach((field) => {
                    if (resource[field] === undefined) {
                        resource[field] = '';
                    }
                });
            });

            store.globalSessions.data.resources = resources;
        },
        addConfigToResources(type) {
            const newResources = store.globalSessions.data.resources;
            if (
                newResources.filter((transporter) => {
                    return (
                        type.fleetName === transporter.fleetName &&
                        type.placeCode === transporter.placeCode
                    );
                }).length > 0
            ) {
                newResources.find((transporter) => {
                    return (
                        type.fleetName === transporter.fleetName &&
                        type.placeCode === transporter.placeCode
                    );
                }).number += type.number;
            } else {
                newResources.push(type);
            }
            store.globalSessions.data.resources = newResources.filter(
                (transporter) => {
                    return transporter.number > 0;
                }
            );
        },
        removeConfigFromPotentialResources(type) {
            const cleanType = { ...type };
            delete cleanType._id;
            store.globalSessions.data.resources =
                store.globalSessions.data.resources.filter(
                    (resource) =>
                        JSON.stringify(resource) !== JSON.stringify(cleanType)
                );
        },
        updateRules(selectedRules) {
            const currentRules = store.globalSessions.data.rules;

            const newRules = currentRules.map((rule) => ({
                ...rule,
                enabled: selectedRules.some(
                    (r) => r.ruleName === rule.ruleName
                ),
            }));
            selectedRules.forEach((rule) => {
                // TODO: looks like prevention for not real use-case
                if (!currentRules.some((r) => r.ruleName === rule.ruleName)) {
                    newRules.push({
                        ...rule,
                        enabled: true,
                    });
                }
            });

            store.globalSessions.data.rules = [...newRules];
        },
        async getGlobalResources() {
            const resources = await apiClient.getResources();
            store.globalSessions.data.resources = resources?.data;
        },
        async reloadIfApiError() {
            const isHealthy = await apiClient.checkHealth();
            if (!isHealthy) window.location.reload();
        },
        async getWorkbenchOrders() {
            const report = await apiClient.getOrders();
            // TODO - in flo-1765 refactor all places that use orders, correct filtering and grouping orders
            const hydratedOrders = await apiClient.getHydratedOrders();
            const hydratedOrdersCount = (hydratedOrders?.data || []).length;
            store.globalSessions.data.hydratedOrders =
                hydratedOrders?.data || [];
            store.globalSessions.data.hydratedOrdersCount = hydratedOrdersCount;
            store.globalSessions.data.orders = report?.data;
            store.globalSessions.data.filteredOrders = report?.data.map((o) => {
                const hydratedOrder =
                    store.globalSessions.data.hydratedOrders.find(
                        (ho) => ho.orderID === o.id
                    );
                if (hydratedOrder)
                    return { ...o, orderNumber: hydratedOrder.orderNumber };
                return o;
            });

            store.globalSessions.data.filteredOrdersCount =
                report?.data?.length || 0;
            const groupedByCompounds = _.chain(report?.data)
                .groupBy('from')
                .map((value, key) => ({ compound: key, orders: value }))
                .value();

            const groupedOrders = groupedByCompounds.map((c) => {
                const groupedByZones = _.chain(c.orders)
                    .groupBy('zone')
                    .map((value, key) => ({ zone: key, zones: value }))
                    .value();

                const zones = groupedByZones.map((zone) => {
                    const zoneOrders = zone.zones.map((o) => {
                        return {
                            id: o.id,
                            from: o.from,
                            to: o.to,
                            vin: o.vin,
                            city: o.city,
                            name: o.name,
                            priority: o.priority,
                            postCode: o.postCode,
                        };
                    });
                    return {
                        zone: zone.zone,
                        orders: zoneOrders,
                        order_number: zoneOrders.length,
                    };
                });

                return {
                    compound: c.compound,
                    zones: _.orderBy(zones, ['zone'], ['asc']),
                    order_number: c.orders.length,
                };
            });
            const sortedOrders = _.orderBy(
                groupedOrders,
                ['order_number'],
                ['desc']
            );
            store.globalSessions.data.zones = sortedOrders;
        },
        async getTransporterTypes() {
            const types = await apiClient.getTransporterTypes();
            store.globalSessions.data.types = types?.data;
        },
        async getLiveTransporters() {
            const types = await apiClient.getLiveTransporters();
            store.globalSessions.data.liveTransporters = types?.data;
        },
        async getPlaces() {
            const places = await apiClient.getPlaces();

            // eslint-disable-next-line no-param-reassign
            store.globalSessions.data.cleanPlaces = transformers.cleanPlaces(
                (places?.data || []).map((p) => ({
                    ...p,
                    zoneGrouping: p.zone || '',
                    postcode: p.postCode || '',
                    type: p.placeType,
                }))
            );

            // eslint-disable-next-line no-param-reassign
            store.globalSessions.data.places = places?.data;
        },
        async getDataQualityReport() {
            const report = await apiClient.getDataQualityReport();
            store.globalSessions.data.report = report?.data;
        },
        async getSystemDQRules() {
            const rules = await apiClient.getSystemDQRules();
            if (rules && rules?.data?.value) {
                const parsed = JSON.parse(rules?.data.value);
                const filterRules = parsed.map((element) => {
                    return {
                        ruleName: element.ruleName,
                        payload: null,
                        enabled: false,
                    };
                });
                const filteredRules = ['FILTER_BY_ORDER', 'FILTER_BY_ZONES'];
                store.globalSessions.data.rules = parsed.filter(
                    (rule) => !filteredRules.includes(rule.ruleName)
                );
                store.globalSessions.data.filteredRules = filterRules.filter(
                    (rule) => filteredRules.includes(rule.ruleName)
                );
            }
        },
        async getSystemConfiguration() {
            const config = await apiClient.getSystemConfiguration();
            config?.data?.forEach((element) => {
                store.globalSessions.data.systemConfiguration[
                    _.camelCase(element.key)
                ] = JSON.parse(element.value);
            });
        },
        async init() {
            await store.globalSessions.reloadIfApiError();
            return Promise.all([
                store.globalSessions.getWorkbenchOrders(),
                store.globalSessions.getGlobalResources(),
                store.globalSessions.getLiveTransporters(),
                store.globalSessions.getTransporterTypes(),
                store.globalSessions.getPlaces(),
                store.globalSessions.getDataQualityReport(),
                store.globalSessions.getSystemDQRules(),
                store.globalSessions.getSystemConfiguration(),
                store.refreshSessions(),
            ]);
        },
    };

    const refreshSessions = async () => {
        if (store.sessionsLoading) return;
        store.sessionsLoading = true;
        try {
            const response = await apiClient.getSolverSessions();
            store.sessions = response?.data;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        } finally {
            store.sessionsLoading = false;
        }
    };

    const createSession = async (
        description,
        resources,
        sessionOperationTypes,
        runIterationOnCreate,
        runWithEfficiency,
        startedBy,
        isReturn
    ) => {
        const { cleanPlaces, rules } = store.globalSessions.data;

        const updatedRules = rules?.concat(
            store.globalSessions.data.filteredRules
        );

        const originPlaceCodes = uniq(
            (resources || []).map(({ placeCode }) => placeCode)
        );
        const originPlaces = (cleanPlaces || []).filter((place) =>
            originPlaceCodes.includes(place.placeID)
        );

        const fleetTypes = uniq(
            (resources || []).map(({ fleetName }) => fleetName)
        ).map((name) => ({
            id: name.replace(/[^a-zA-Z0-9]+/g, ''),
            name,
        }));

        const filteredZones =
            (updatedRules || [])
                .find((r) => r.ruleName === 'FILTER_BY_ZONES')
                ?.payload?.map((r) => r.value) || [];

        const filteredOrders =
            (updatedRules || [])
                .find((r) => r.ruleName === 'FILTER_BY_ORDER')
                ?.payload?.map((r) => r.value) || [];

        const sessionPayload = {
            sessionID: uuidv4(),
            description:
                description ||
                `${DateTime.now().toFormat('dd LLL yyyy')} UNKNOWN`,
            params: {
                runIterationOnCreate,
                fixedDate: runWithEfficiency
                    ? `${DateTime.now().toFormat('yyyy-LL-dd')}`
                    : null,
                rules: [
                    {
                        id: uuidv4(),
                        name: 'FILTER_BY_ZONES',
                        config: sessionOperationTypes,
                        enabled: filteredZones.length > 0,
                        payload: filteredZones,
                    },
                    {
                        id: uuidv4(),
                        name: 'FILTER_BY_ORDER',
                        enabled: filteredOrders.length > 0,
                        payload: filteredOrders,
                    },
                ],
            },
            orders: [],
            resources: (resources || [])
                .reduce((rcs, { fleetName, number, placeCode }) => {
                    const origin = originPlaces.find(
                        ({ placeID }) => placeID === placeCode
                    );
                    const startWaypoint = {
                        ...initialWaypointValue,
                        id: origin.placeID,
                        name: origin.name,
                        location: origin.location,
                        customers: [
                            {
                                place: origin,
                            },
                        ],
                    };
                    const endWaypoint = isReturn
                        ? startWaypoint
                        : initialWaypointValue;

                    return [
                        ...rcs,
                        ...Array(Number(number)).fill({
                            name: '',
                            origin,
                            start: { time: '', waypoint: startWaypoint },
                            end: { time: '', waypoint: endWaypoint },
                            type: fleetTypes.find(
                                ({ name }) => name === fleetName
                            ),
                        }),
                    ];
                }, [])
                .map((res) => ({ ...res, id: uuidv4() })),

            initRoutes: [],
            creator: 'HUMAN',
            createdBy: startedBy,
            recordStatus: 'NEW',
        };

        try {
            const sessionCreated = await apiClient.createSolverSession(
                sessionPayload.sessionID,
                sessionPayload
            );

            store.currentSession.sessionID = sessionCreated?.data?.session_id;
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        } finally {
            await store.refreshSessions();
            await store.globalSessions.clear();
        }
    };

    const deleteSession = async (id) => {
        try {
            await apiClient.deleteSession(id);
        } catch (err) {
            // eslint-disable-next-line no-console
            console.error(err);
        } finally {
            await store.refreshSessions();
        }
    };

    const selectSession = async (id) => {
        await currentSession.clear();
        store.currentSession.sessionID = id;
    };

    return {
        refreshSessions,
        createSession,
        deleteSession,
        selectSession,
        currentSession,
        globalSessions,
    };
};
