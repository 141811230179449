import { ReactNode } from 'react';
import classNames from 'classnames';
import {
    camelCase,
    chain,
    cloneDeep,
    sortBy,
    uniqBy as uniqueBy,
    orderBy,
} from 'lodash';
import { transformers } from '@flo-concepts/data-parser';
import { FloTypes, Session } from '@flo-concepts/flo-types';
import { Place, SolverScenario } from '@models';
import { FloCMultiSelectOption } from '@components/FloCElements/FloCMultiSelect';
import {
    SourceObjectType,
    ZoneObjectType,
} from '@components/SolverSessions/SessionCreator/SessionCreator.model';
import {
    CompoundWithZoneOrders,
    State,
    ZoneWithOrders,
} from './Container/MainReducer.model';
import { SyncStatus } from './Main.model';

export const ORDERS_PER_PAGE = 20;
export const ORDERS_PER_PAGE_STORAGE_KEY = 'ordersPerPage';
export const ORDERS_PER_PAGE_OPTIONS = [ORDERS_PER_PAGE, 30, 50, 100];
export const ALL_ZONES_LABEL = 'All Zones';

export enum NoResource {
    name = 'No Resource',
    id = 'noResource',
}

export const getSyncStatusColor = (status: string) => {
    switch (status) {
        case SyncStatus.STATUS_SUCCESS:
            return 'success';
        case SyncStatus.STATUS_PENDING:
            return 'warning';
        case SyncStatus.STATUS_CREATED:
            return 'warning';
        case SyncStatus.STATUS_FAILED:
            return 'danger';
        case SyncStatus.STATUS_UNKNOWN:
            return 'dark';
        default:
            return 'dark';
    }
};

export const convertSolverScenarios = (
    solverScenariosList: Array<SolverScenario>
): Array<FloCMultiSelectOption> =>
    solverScenariosList.map(({ scenarioName, scenarioID }) => ({
        text: scenarioName,
        value: scenarioID,
        selected: false,
    }));

export const createChosenScenarios = (
    solverScenarios: Array<FloCMultiSelectOption>,
    chosenScenarios: Array<FloCMultiSelectOption>
) => {
    const chosen = chosenScenarios.map((s) => s.value);
    return solverScenarios.map((scenario) => {
        return {
            ...scenario,
            selected: chosen.includes(scenario.value),
        };
    });
};

export const convertScenariosResults = (
    scenarios: Array<FloCMultiSelectOption>
): Array<string> =>
    scenarios.filter((s) => s.selected).map((s) => String(s.value));

export const convertSnakeCaseKeys = <F, T>(lastSyncData: F): T => {
    return Object.keys(lastSyncData).reduce((acc, key) => {
        const camelCasedKey = camelCase(key) as keyof T & string;
        (acc as Record<string, any>)[camelCasedKey] =
            lastSyncData[key as keyof F];
        return acc;
    }, {} as T);
};

export const createZonesFromOrders = (
    orders: State['orders']
): Array<CompoundWithZoneOrders> => {
    const groupedByCompounds = chain(orders || [])
        .map((o) => ({
            ...cloneDeep(o),
            fromCode: o.from.code,
            zone: o.to.group,
        }))
        .groupBy('fromCode')
        .map((value, key) => ({ compound: key, orders: value }))
        .value();

    const groupedOrders = groupedByCompounds.map((c) => {
        const groupedByZones = chain(c.orders)
            .groupBy('zone')
            .map((value, key) => ({ zone: key, orders: value }))
            .value();

        const zones = groupedByZones.map((grouped) => {
            const zoneOrders = grouped.orders.map((o) => {
                return {
                    id: o.orderID,
                    from: o.from.name,
                    to: o.to.name,
                    vin: o.vin,
                    city: o.to.location.city,
                    name: o.vehicle.name,
                    priority: o.priority,
                    postCode: o.to.location.postCode,
                };
            });
            return {
                zone: grouped.zone,
                orders: zoneOrders,
                orderNumber: zoneOrders.length,
            };
        });

        return {
            compound: c.compound,
            zones: orderBy(zones, ['zone'], ['asc']),
            orderNumber: c.orders.length,
        };
    });

    return orderBy(groupedOrders, ['orderNumber'], ['desc']);
};

export const generateScopedColumn = (
    value: string | ReactNode,
    className?: string,
    testId?: string
) => {
    return (
        <td data-testid={testId} className={classNames(className)}>
            {value}
        </td>
    );
};
export const cleanPlaces = (places: Array<Place>) =>
    transformers.cleanPlaces(
        (places || []).map((p) => ({
            ...p,
            zoneGrouping: p.zone || '',
            postcode: p.postCode || '',
            type: p.placeType as FloTypes.PlaceType,
        }))
    );

export const mapOrderPlaceToSource = (
    name: string,
    placeID: string,
    placeType: string,
    type: 'from' | 'to',
    orders: number,
    zones: Array<ZoneWithOrders>,
    location?: FloTypes.Location
) => ({
    name,
    placeID,
    placeType,
    type,
    orders,
    location,
    zones: zones
        ?.map(
            ({ zone, orderNumber }: { zone: string; orderNumber: number }) => ({
                name: zone,
                orders: orderNumber,
            })
        )
        .sort(({ name: za }: ZoneObjectType, { name: zb }: ZoneObjectType) => {
            if (za < zb) return -1;
            if (za > zb) return 1;
            return 0;
        }),
});

export const createSourcesOfOrders = (
    orders: Array<FloTypes.Hydrated.Order>,
    zones: Array<CompoundWithZoneOrders>
) => {
    if (!orders?.length) return [];

    return sortBy(
        uniqueBy(
            [
                ...orders.map((order) => {
                    const compound = zones.find(
                        (z: { compound: string }) =>
                            z.compound === order.from?.placeID
                    );

                    if (compound)
                        return mapOrderPlaceToSource(
                            order.from.name,
                            order.fromPlaceID,
                            order.from.type,
                            'from',
                            compound.orderNumber,
                            compound.zones,
                            order.from.location
                        );

                    return null;
                }),
                ...orders.map((order) => {
                    const compound = zones.find(
                        (z: { compound: string }) =>
                            z.compound === order.to?.placeID
                    );

                    if (compound)
                        return mapOrderPlaceToSource(
                            order.to.name,
                            order.toPlaceID,
                            order.to.type,
                            'to',
                            compound.orderNumber,
                            compound.zones,
                            order.to.location
                        );

                    return null;
                }),
            ].filter((c) => c),
            'placeID'
        ),
        'name'
    );
};

export const createCompoundsFromPlaces = (
    places: Array<FloTypes.Place>,
    zones: Array<CompoundWithZoneOrders>
): Array<SourceObjectType> => {
    return places
        .map(
            ({
                name,
                placeID,
                location,
                type,
            }: {
                name: string;
                placeID: string;
                location: FloTypes.Location;
                type: string;
            }) => {
                const compound = zones.find(
                    (z: { compound: string }) => z.compound === placeID
                );

                if (compound) {
                    return {
                        name,
                        placeID,
                        location,
                        placeType: type,
                        orders: compound.orderNumber,
                        zones: compound.zones
                            ?.map(
                                ({
                                    zone,
                                    orderNumber,
                                }: {
                                    zone: string;
                                    orderNumber: number;
                                }) => ({
                                    name: zone,
                                    orders: orderNumber,
                                })
                            )
                            .sort(
                                (
                                    { name: za }: ZoneObjectType,
                                    { name: zb }: ZoneObjectType
                                ) => {
                                    if (za < zb) return -1;
                                    if (za > zb) return 1;
                                    return 0;
                                }
                            ),
                    };
                }
                return null;
            }
        )
        .filter((cmp) => cmp)
        .sort(({ orders: ca }, { orders: cb }) => {
            if (ca < cb) return 1;
            if (ca > cb) return -1;
            return 0;
        });
};

export const createResourcesFromTransporters = (
    transporters: Array<Session.Transporter>
) =>
    transporters.map((t: { name: string }) => ({
        id: t.name.replace(/[^a-zA-Z0-9]+/g, ''),
        name: t.name,
    }));
